import React, { useState, useEffect, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux'



import './LangSet.css'

import {
    Link,
    NavLink
} from "react-router-dom";

export default function LangSet(props){

    const dispatch = useDispatch();
    const flags = [
        {lang:'de', url:'/svg/flags/de.svg', alt:'DE'},
        {lang:'en', url:'/svg/flags/en.svg', alt:'EN'}

    ]

    var qstr = window.location.toString();
    let url = new URL(qstr);
    let ltmp= url.pathname.split('/');
    var path1 = ltmp[2] ? '/'+ltmp[2] : '';
    var path2 = ltmp[3] ? '/'+ltmp[3] : '';
    var path3 = ltmp[4] ? '/'+ltmp[4] : '';
    let path = path1+path2+path3;

return(
    <ul className='flags'>
    {
        flags.map((i, key) => 
            <li key={key} className={props.active===i.lang?'active':null}>
                <Link to={i.lang+path}><img src={i.url} alt={i.alt} title={i.alt} height='15'/></Link>
            </li>
        )
    }
    </ul>
)
function setLang(lang){
    dispatch({type:'SET_LANG', payload:lang});
}
}